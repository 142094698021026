
import { defineComponent, onMounted, ref, computed, watch } from "vue";
import { useRoute } from "vue-router";
import contacts from "@/core/data/contacts";
import MessageIn from "@/components/messenger-parts/MessageIn.vue";
import MessageOut from "@/components/messenger-parts/MessageOut.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";
import { ElNotification } from "element-plus/es";
import { statusColor, translate } from "@/core/services/HelpersFun";
import UserInfo from "@/core/services/UserInfo";
import { useStore } from "vuex";
import { Mutations } from "@/store/enums/StoreEnums";
import JwtService from "@/core/services/JwtService";
import Notice from "@/components/Notice.vue";

export default defineComponent({
  name: "kt-customer-live-chat-page",
  components: {
    MessageIn,
    MessageOut,
    Notice,
  },
  setup() {
    const store = useStore();
    const messagesRef = ref<null | HTMLElement>(null);
    const messagesInRef = ref<null | HTMLElement>(null);
    const messagesOutRef = ref<null | HTMLElement>(null);
    const user = UserInfo.getUser();
    const files = ref();
    const route = useRoute();
    const messages = ref();
    const conversations = ref();
    const lastMessageId = ref();
    const lastTry = ref(0);
    const newMessageText = ref("");
    const conversationId = ref();
    const selectedBot = ref();
    const messageType = ref();
    const accept = computed(() => {
      if (messageType.value === "image") {
        return "image/*";
      }
      if (messageType.value === "video") {
        return "video/*";
      }
      if (messageType.value === "audio") {
        return "audio/ogg";
      }

      return "*";
    });

    const loading = ref(true);
    const loadingMessages = ref(false);
    const isGroupChat = computed(() => {
      return route.path.indexOf("/group-chat") !== -1;
    });
    const bots = ref();
    const list = ref();
    const pagination = ref();
    const page = ref(1);
    const statisticsData = ref();
    const filterData = ref();

    const getBots = () => {
      ApiService.query("/bot", {}).then(({ data }) => {
        bots.value = data.data.bots.data;
      });
    };

    watch(selectedBot, () => {
      filterBot(selectedBot.value);
    });

    const getConversations = () => {
      loading.value = true;
      ApiService.get(`/chat?page=${page.value}`)
        .then(({ data }) => {
          conversations.value = data.data.chats.data;
          filterData.value = conversations.value;

          pagination.value = data.data.chats.pagination;
          loading.value = false;
        })
        .catch(() => {
          loading.value = false;
          ElNotification({
            title: translate("error"),
            message: translate("Error loading messages"),
            type: "error",
          });
        });
    };

    const getMessage = () => {
      if (lastTry.value === lastMessageId.value) {
        return false;
      } else {
        lastTry.value = lastMessageId.value;
      }

      loadingMessages.value = true;
      ApiService.get(
        `/chat/messages?mobile=${activeConversation.value.chat_id}&bot_id=${activeConversation.value.bot.bot_id}`
      )
        .then(({ data }) => {
          if (messages.value) {
            if (lastMessageId.value === null) {
              loadingMessages.value = false;
              return false;
            }

            if (data.data.messages.length) {
              data.data.messages.map((item) => {
                messages.value.push(item);
              });
            }
          } else {
            messages.value = data.data.messages;
          }
          loadingMessages.value = false;
          lastMessageId.value = data.data.first_message;
        })
        .catch(() => {
          loadingMessages.value = false;
          ElNotification({
            title: translate("error"),
            message: translate("Error loading messages"),
            type: "error",
          });
        });
    };

    const handleSizeChange = (val: number) => {
      return;
    };

    const handleCurrentChange = (val: number) => {
      page.value = val;
      getConversations();
    };

    const activeConversation = ref();
    watch(conversationId, () => {
      let v = conversations.value.find(
        (item) => item.chat_id === conversationId.value
      );
      activeConversation.value = JSON.parse(JSON.stringify(v));
      getMessage();
    });

    const fileInputKey = ref(0);
    const addNewMessage = () => {
      if (!newMessageText.value && !files.value) return;
      // send message
      const formData = new FormData();
      // if (files.value.files[0]) formData.append("file", files.value.files);

      if (files.value) formData.append("attachment_file", files.value);
      if (newMessageText.value)
        formData.append("message", newMessageText.value);
      formData.append("mobile", activeConversation.value.chat_id);
      formData.append("bot_id", activeConversation.value.bot.bot_id);
      formData.append(
        "message_type",
        !files.value ? "message" : messageType.value
      );

      ApiService.setHeader();
      ApiService.post("chat/messages/send", formData)
        .then(({ data }) => {
          let mes = {
            message_id: data.data.message.id,
            type: "out",
            name: translate("me"),
            image: user && user.picture,
            message: data.data.message.messages[0].message,
            file: data.data.message.messages[0].attachment_file,
            time: data.data.message.created_at.diff_for_humans,
            status: "pending",
          };
          messages.value.unshift(mes);
          files.value = null;
        })
        .catch(({ response }) => {
          store.commit(Mutations.SET_ERROR, response.data);
        });

      setTimeout(() => {
        if (messagesRef.value) {
          messagesRef.value.scrollTop = messagesRef.value.scrollHeight;
        }
      }, 2000);
      newMessageText.value = "";
      if (files.value) ++fileInputKey.value;
    };

    const mobileUiChat = computed(() => {
      return window.innerWidth < 992;
    });

    const activeBox = ref(true);

    const filterBot = (id) => {
      filterData.value = conversations.value;
      if (id === "all") return false;
      else
        filterData.value = filterData.value.filter(
          (item) => item.bot.bot_id === id
        );
    };

    // upload file
    const componentVersion = ref(0);
    const uploadActionUrl = () => {
      return process.env.VUE_APP_UPLOAD_FILE_URL + `upload/file`;
    };

    const uploadCallback = (res, file, fileList, index) => {
      files.value = res.data.path;
      addNewMessage();
    };

    // const connectToSocket = () => {
    //   if (JwtService.getUserId()) {
    //     window.Echo.private("user." + JwtService.getUserId()).listen(
    //       ".multiuser_message_sent",
    //       (e) => {
    //         if (
    //           activeConversation.value &&
    //           activeConversation.value.chat_id === e.data.chat_id
    //         ) {
    //           // let find = messages.value.find(
    //           //   (item) => item.message_id == e.data.message_id
    //           // );
    //           // if (find) find.status = "send";
    //           let find = messages.value[0];
    //           find.status = "send";
    //         }
    //       }
    //     );
    //   }
    // };

    onMounted(() => {
      getBots();
      getConversations();
      setCurrentPageBreadcrumbs("Overview", ["Chat"]);
      // connectToSocket();
      // Detect when scrolled to bottom. in chat
      const listElm = document.querySelector("#kt_chat_scroll_admin");
      if (listElm) {
        listElm.addEventListener("scroll", () => {
          if (
            listElm.clientHeight + -listElm.scrollTop + 2 >=
            listElm.scrollHeight
          ) {
            if (lastMessageId.value !== undefined) {
              getMessage();
            }
          }
        });
      }

      const chatListHolder = document.querySelector("#kt_chat_scroll_admin");
      if (chatListHolder) {
        chatListHolder.addEventListener("scroll", () => {
          if (
            chatListHolder.clientHeight + chatListHolder.scrollTop + 2 >=
            chatListHolder.scrollHeight
          ) {
            if (page.value !== 1) {
              getConversations();
            }
          }
        });
      }
    });

    return {
      list,
      loading,
      loadingMessages,
      pagination,
      statisticsData,
      handleSizeChange,
      handleCurrentChange,

      messages,
      conversations,
      messagesRef,
      newMessageText,
      addNewMessage,
      messagesInRef,
      messagesOutRef,
      contacts,
      isGroupChat,

      getMessage,
      statusColor,
      translate,
      user,
      activeConversation,
      lastTry,
      lastMessageId,
      conversationId,
      mobileUiChat,
      activeBox,
      page,
      getConversations,

      files,
      uploadActionUrl,
      uploadCallback,
      componentVersion,
      filterData,
      bots,
      selectedBot,
      messageType,
      accept,
      fileInputKey,
    };
  },
});
